import React from "react"
import PropTypes from "prop-types"
import classes from "./SectionTitleVerticalSeperator.module.scss"

const sectionTitleVerticalSeperator = (props) => {

  return (
    <div className={classes.TitleSeperator}>
      <span className={classes.SepVerticalLineHolder}>
        <span className={classes.SepVerticalLine}/>
      </span>
      <h4 className={classes.Heading}>{props.title}</h4>
      <span className={classes.SepVerticalLineHolder}>
        <span className={classes.SepVerticalLine}/>
      </span>

    </div>
  )
}
sectionTitleVerticalSeperator.propTypes = {
  title: PropTypes.string.isRequired,
}
export default sectionTitleVerticalSeperator
