import React from "react"
import classes from "./portfolioSeeProjectBtn.module.scss"

const portfolioSeeProjectBtn = (props) => {
  return (
    <div className={classes.Wrapper}>
      <a target="_blank" rel="noopener noreferrer" className={classes.Link} href={props.url}>
        <h4 className={classes.LinkText}>{props.btnText}</h4>
      </a>
    </div>
  )
}

export default portfolioSeeProjectBtn
